import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { Grid } from '#components/Trustpilot/Grid'
import { TrustpilotGridPanelStoryblok } from '#types/storyblok-component-types'
const TrustpilotGridPanelComponent = ({
  blok,
}: {
  blok: TrustpilotGridPanelStoryblok
}) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-auto mb-8 flex w-full max-w-5xl flex-col gap-8 px-4 pt-8 md:px-6 md:pt-12">
        <h2 className="q-text-header-4xl self-center text-balance">
          {blok.heading}
        </h2>
        <Grid />
      </div>
    </div>
  )
}
export const TrustpilotGridPanel = ({
  blok,
}: {
  blok: TrustpilotGridPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <TrustpilotGridPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
