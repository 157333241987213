import { cva } from 'class-variance-authority'

import { cn } from '../utils'

const dividerStyles = cva('bg-gray-300', {
  variants: {
    orientation: {
      vertical: 'inline-block h-full w-px',
      horizontal: 'block h-px w-full',
    },
  },
})

export function Separator({
  className,
  orientation,
}: {
  className?: string
  orientation: 'vertical' | 'horizontal'
}) {
  return (
    <span className={cn(dividerStyles({ orientation }), className)}>
      &nbsp;
    </span>
  )
}
