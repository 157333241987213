import 'flag-icons/css/flag-icons.min.css'

import { zodResolver } from '@hookform/resolvers/zod'
import { useCanaryClient } from '@qogita/canary-client'
import { BuyerCurrency, Country } from '@qogita/canary-types'
import { countries, getCountryName } from '@qogita/countries'
import { logWarning } from '@qogita/logging'
import {
  Anchor,
  Button,
  Checkbox,
  Form,
  FormControl,
  FormDescription,
  FormFieldController,
  FormItem,
  FormLabel,
  FormMessage,
  InlineNotification,
  Modal,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@qogita/ui'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { getInfoQueries } from 'src/api/info-queries'
import { z } from 'zod'

import { useAuthentication } from '#contexts/Authentication'
import { useLocalisationPreferences } from '#hooks/api/useLocalisationPreferences'
import { useUpdateLocalisationPreferences } from '#hooks/api/useUpdateLocalisationPreferences'
import { includes } from '#lib/array'
import { INFO_DATA_SNAPSHOT } from '#lib/info'

const globalPreferencesSchema = (allowedCountries: Country[]) =>
  z.object({
    currency: z.enum(['GBP', 'EUR', 'USD'], { required_error: 'Required' }),
    country: z
      .string()
      .min(1, 'Required')
      .refine(
        (value): value is Country => includes(allowedCountries, value),
        'Invalid country',
      ),
    showOnlyTraceableStock: z.boolean(),
  })

const currencyMap: Record<BuyerCurrency, string> = {
  EUR: '€ Euro (EUR)',
  GBP: '£ British Pound (GBP)',
  USD: '$ U.S. Dollar (USD)',
}

const currencyItems = Object.entries(currencyMap).map(([value, label]) => ({
  value,
  label,
}))

const UPDATE_LOCALISATION_ERROR_CATCH_ALL =
  'There was a problem updating your localisation preferences. Please try again or speak to a member of our team if the issue persists.'

type MastheadLocalisationProps = {
  className?: string
}
export const MastheadLocalisation = ({
  className,
}: MastheadLocalisationProps) => {
  const canaryClient = useCanaryClient()
  const infoQueries = getInfoQueries(canaryClient)
  const { data: infoData } = useQuery(infoQueries.all())
  const info = infoData ?? INFO_DATA_SNAPSHOT
  const [isOpen, setIsOpen] = useState(false)
  const { country, currency, showOnlyTraceableStock, isPending } =
    useLocalisationPreferences()
  const updatePreferences = useUpdateLocalisationPreferences()
  const { isAuthenticated } = useAuthentication()

  const form = useForm({
    values: {
      country,
      currency,
      showOnlyTraceableStock,
    },
    resolver: zodResolver(
      globalPreferencesSchema(info.allowedShippingCountries),
    ),
    mode: 'onBlur',
  })

  const handleSubmit = form.handleSubmit(
    async (values) => {
      updatePreferences.mutate(values, {
        onSuccess: () => {
          setIsOpen(false)
        },
      })
    },
    (error) =>
      logWarning('MastheadLocalisationForm client validation failed', error),
  )

  const countryCodeClass = `fi-${country?.toLowerCase() ?? 'gb'}`

  const selectedCountry = form.watch('country')
  const isCountryDisallowedForShipping =
    selectedCountry !== undefined &&
    !info.allowedShippingCountries.includes(selectedCountry)

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <Modal.Trigger
        className={clsx(
          'fib fis border-info-700 hidden h-[1.625rem] w-[1.625rem] items-center justify-center overflow-hidden rounded-full border shadow-[inset_0_0_0_1px_rgba(255,255,255)] transition-opacity sm:flex',
          className,
          countryCodeClass,
          { 'opacity-0': isPending }, // Hide with a fade-in if preferences are still loading
        )}
        aria-label="Update shipping location and currency"
      ></Modal.Trigger>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Update shopping preferences</Modal.Title>
        </Modal.Header>
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="flex flex-col gap-6">
              <p>
                Select your current shipping location or choose your preferred
                payment currency.
              </p>
              <FormFieldController
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shipping country</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl className="col-span-full">
                        <SelectTrigger>
                          <SelectValue
                            key={field.value}
                            placeholder="Select a country"
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {countries
                          .filter(({ countryCode }) =>
                            includes(
                              info.allowedShippingCountries,
                              countryCode,
                            ),
                          )
                          .map(({ countryCode, name }) => (
                            <SelectItem key={countryCode} value={countryCode}>
                              {name}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormDescription className="text-xs">
                      Your shipping country is the region where you choose to
                      receive your goods.
                    </FormDescription>
                    <FormMessage />
                    {isCountryDisallowedForShipping ? (
                      <FormDescription asChild>
                        <InlineNotification variant="info">
                          <InlineNotification.Description>
                            We currently do not ship to{' '}
                            {getCountryName(country)}. Please select a country
                            in the EU or UK to browse our catalog.
                          </InlineNotification.Description>
                        </InlineNotification>
                      </FormDescription>
                    ) : null}
                  </FormItem>
                )}
              />
              <FormFieldController
                name="currency"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Currency</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            key={field.value}
                            placeholder="Select a currency"
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {currencyItems.map(({ value, label }) => (
                          <SelectItem key={value} value={value}>
                            {label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription className="text-xs">
                      Chosen currency impacts your payments and catalog display.
                      Invoicing currency is determined by your shipping
                      location.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isAuthenticated ? (
                <>
                  <Separator orientation="horizontal" />
                  <div className="flex flex-col gap-3">
                    <p>
                      Select if you want to filter products provided with
                      invoices for marketplaces.
                    </p>
                    <FormFieldController
                      control={form.control}
                      name="showOnlyTraceableStock"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center gap-2">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <FormLabel className="text-base">
                            Only show products with marketplace invoices
                            available
                          </FormLabel>
                        </FormItem>
                      )}
                    />
                    <p className="text-pretty bg-gray-100 p-3">
                      <strong>
                        Using this filter will cause restrictions on the
                        availability of products.
                      </strong>{' '}
                      This filter is only necessary for those who require an
                      invoice for traceability on marketplaces.{' '}
                      <Anchor
                        size="inline"
                        href="https://help.qogita.com/proving-chain-of-supply"
                        target="_blank"
                      >
                        Learn more
                      </Anchor>
                    </p>
                  </div>
                </>
              ) : null}
              {updatePreferences.isError ? (
                <InlineNotification variant="error">
                  <InlineNotification.Description>
                    {UPDATE_LOCALISATION_ERROR_CATCH_ALL}
                  </InlineNotification.Description>
                </InlineNotification>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Modal.Actions>
                <Modal.Close asChild>
                  <Button color="primary" appearance="outlined">
                    Cancel
                  </Button>
                </Modal.Close>
                <Button type="submit" isLoading={updatePreferences.isPending}>
                  <Button.Loader>Save</Button.Loader>
                </Button>
              </Modal.Actions>
            </Modal.Footer>
          </Form>
        </FormProvider>
      </Modal.Content>
    </Modal>
  )
}
