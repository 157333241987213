import { CartLine } from '@qogita/canary-types'
import Image from 'next/image'
import Link from 'next/link'
import { ComponentProps } from 'react'

import { getUrlPathname } from '#lib/url'

import { CurrencyAmount } from './CurrencyAmount'
import { ActiveCartAnchor } from './pages/CartQidPage/CartLink'
import {
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastTitle,
} from './Toast/Toast'
import { useToast } from './Toast/useToast'

function AddToCartToast({
  variant,
  quantity,
  isDeal,
  price,
  priceCurrency,
}: {
  variant: Pick<CartLine['variant'], 'fid' | 'slug' | 'name' | 'images'>
  quantity: number
  isDeal?: boolean
} & Pick<CartLine, 'price' | 'priceCurrency'>) {
  const thumbnailUrl = variant.images[0]?.url
  const { name, fid, slug } = variant
  return (
    <div className="rounded border border-gray-300 bg-white">
      <div className="flex justify-between gap-4 p-4">
        <ToastTitle asChild>
          <h2 className="font-bold">Added to cart</h2>
        </ToastTitle>
        <ToastClose />
      </div>
      <div className="p-4">
        <div className="flex items-start gap-6">
          <Image
            src={getUrlPathname(thumbnailUrl)}
            width={40}
            height={40}
            alt=""
            className="aspect-square object-contain"
          />
          <div className="flex flex-col gap-1">
            <ToastDescription asChild>
              <h3 className="text-sm">
                <Link
                  className="text-info-700 hover:text-info-800 focus:text-info-800"
                  href={{
                    pathname: '/products/[fid]/[slug]/',
                    query: {
                      fid,
                      slug,
                    },
                  }}
                >
                  {name}
                </Link>
              </h3>
            </ToastDescription>
            <div className="flex gap-3 text-xs text-gray-500">
              <p>Requested quantity: {quantity}</p>
              <p>
                Price:{' '}
                <CurrencyAmount amount={price} currency={priceCurrency} />
                {isDeal ? ' 🔥' : null}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <ToastAction altText="Go to cart">
          <ActiveCartAnchor
            color="primary"
            appearance="outlined"
            className="w-full"
          >
            View cart
          </ActiveCartAnchor>
        </ToastAction>
      </div>
    </div>
  )
}

export function useAddToCartToast() {
  const toastFunctions = useToast()

  return {
    ...toastFunctions,
    toast: (props: ComponentProps<typeof AddToCartToast>) =>
      toastFunctions.toast({
        toastType: 'custom',
        toast: <AddToCartToast {...props} />,
      }),
  }
}
